import React from "react"
import { graphql } from "gatsby"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"

import Layout from "../components/layout"

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    sanityVideo {
      videoUpload
    }
  }
`

function videos({ data }) {
  const videos = data.sanityVideo.videoUpload

  console.log(videos)

  const videoWrapperStyles = css`
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
  `

  return (
    <Layout>
      <Global
        styles={css`
          .embed-container {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
            max-width: 100%;
            flex: 0 0 100%;
            flex-flow: row wrap;
            margin-bottom: var(--size-8);
            @media (min-width: 900px) {
              padding-bottom: calc(56.25% * 0.45);
              flex: 0 0 45%;
            }
          }
          iframe,
          object,
          embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        `}
      />
      {/* <div className="pageHeader">
        <h1>{faqs.title}</h1>
      </div> */}
      {/* 
      <PageHeader
        overlay
        backgroundImage={faqs.faqsImage.imageItem}
        urlFor={urlFor}
      >
        <h1>{faqs.title}</h1>
      </PageHeader> */}

      <div
        className="sectionContent"
        css={css`
          max-width: 1200px;
          overflow: hidden;
        `}
      >
        <div css={videoWrapperStyles}>
          {videos.map((video, index) => (
            <div
              className="embed-container"
              dangerouslySetInnerHTML={{ __html: video }}
            />
          ))}
        </div>
      </div>
      <div
        className="sectionContent"
        css={css`
          max-width: 800px;
        `}
      >
        {/* <ContactForm /> */}
      </div>
    </Layout>
  )
}

export default videos
